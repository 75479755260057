<template>
  <div
    v-loading="loading"
    class="up_image_model"
  >
    <el-button
      v-if="!disabled"
      type="primary"
      size="small"
      :disabled="showImage.length >= num"
      @click="onUpImage"
    >
      点击上传
    </el-button>
    <div class="up_image_box">
      <draggable
        v-model="showImage"
        class="drag-box"
        element="div"
        :force-fallback="true"
        :disabled="disabled"
        :animation="100"
        @sort="onChangeSort"
      >
        <div
          v-for="(item,i) in showImage"
          :key="i"
          class="up_image_line"
        >
          <el-image
            class="up_image"
            style="width: 100px"
            :src="item"
            :preview-src-list="showImage"
          >
            <div slot="placeholder" class="image-loading">
              <i  style="font-size: 20px;" class="el-icon-loading">
              </i>
            </div>
          </el-image>
          <div
            v-if="!disabled"
            class="clear_icon"
            @click="clearImage(i)"
          >
            <i class="el-icon-circle-close"></i>
          </div>
        </div>
      </draggable>
    </div>
    <!-- 图片上传辅助 -->
    <input
      ref="upload"
      style="display: none;"
      type="file"
      :multiple="num > 1"
      accept="image/gif,image/jpeg,image/jpg,image/png"
      @change="changeImage($event)"
    />
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter';
import draggable from 'vuedraggable';
import { upload } from '@/utils/oss'

export default {
  name: '',
  components: {
    draggable,
  },
  mixins: [Emitter],
  props: {
    imageList: {
      type: [Array, String],
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
      default: 'array'
    },
    num: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      showImage: [],
      AccessInfo: {
        accessKeyId: '',
        AccessKeySecret: '',
        regionId: 'oss-cn-hangzhou',
        bucket: 'wsnbh',
        dir: 'default',
      },
    };
  },
  computed: {},
  watch: {
    imageList: {
      handler(val) {
        if (this.type === 'string') {
          if (val !== '' && val) {
            if (val.split) {
              this.showImage = val.split(',');
            }
          }
        } else {
          this.showImage = val;
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    onChangeSort() {
      let data = this.type === 'array' ? this.showImage : this.showImage.join()
      this.$emit('update:imageList', data);
      this.dispatch('ElFormItem', 'el.form.change', data);
      this.$emit('change', data);
    },
    // 删除
    clearImage(i) {
      this.$confirm('确定删除该图片吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.showImage.splice(i, 1);
        let data = this.type === 'array' ? this.showImage : this.showImage.join()
        this.$emit('update:imageList', data);
        this.dispatch('ElFormItem', 'el.form.change', data);
        this.$emit('change', data);
      }).catch(() => {
      });
    },
    // 上传图片
    async changeImage(e) {
      if (!e.target.files[0]) return;
      for (let i = 0; i < e.target.files.length; i++) {
        let apiUrl = await this.multipartUpload(e.target.files[i]);
        if (this.showImage.length >= this.num) {
          this.$message.error(`最多只能上传${this.num}张图片`);
          return;
        }
        this.showImage.push(apiUrl);
        let data = this.type === 'array' ? this.showImage : this.showImage.join()
        this.$emit('update:imageList', data);
        this.dispatch('ElFormItem', 'el.form.change', data);
        this.$emit('change', data);
      }
    },
    onUpImage() {
      this.$refs.upload.value = '';
      this.$refs.upload.click();
    },
    // 定义上传方法
    multipartUpload(file) {
      return new Promise(async (resolve, reject) => {
        this.loading = true;
        upload(file).then((res)=>{
          const url = res.url.split('?')[0];
          resolve(url);
        }).finally(()=>{
          this.loading = false;
        })
      });
    },
  },
};
</script>

<style lang="scss">
.up_image {
  .el-image__preview {
    height: auto;
    border: 1px solid #ccc;
  }
  min-height: 60px;
  min-width: 100px;
}
</style>
<style scoped lang="scss">
.up_image_model {
  .up_image_box {
    display: flex;
    flex-wrap: wrap;
    .up_image_line {
      position: relative;
      display: flex;
      margin-right: 10px;
      .clear_icon {
        position: absolute;
        cursor: pointer;
        top: 0px;
        right: 0px;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 10px;
        background: rgba(0, 0, 0, 0.3);
        line-height: 20px;
        text-align: center;
      }
    }
  }
}


.image-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-box{
  display: flex;
  flex-wrap: wrap;
}
</style>
